import axios from 'axios';
import { Toast } from 'vant';
import store from '@/vuex/main';

const CancelToken = axios.CancelToken;
// const source = CancelToken.source();
let cancelTokens = [];

function cancelReauest () {
	for (let i = 0; i < cancelTokens.length; i++) {
		cancelTokens[i]();
	}
	cancelTokens = [];
}

const BASE_API = {
	development: 'http://localhost:3444/',
	test: 'https://vas-api-st.vip.shenshenle.cn/',
	production: 'https://vas-api.vip.shenshenle.cn/'
};
window.BASE_URL = BASE_API[process.env.NODE_ENV || 'production'];
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
const service = axios.create({
	baseURL: BASE_API[process.env.NODE_ENV || 'production'], // api的base_url
	// withCredentials: 'include',
	timeout: 350000 // request timeout
});

// 拦截器
service.interceptors.request.use(config => {
	if (config.params) {
		config.params.retryt = Math.random();
	} else {
		config.params = { retryt: Math.random() };
	}
	!store.state.isPageLoading && (store.state.isPageLoading = true);
	return config;
}, error => {
	console.log(error); // for debug
	Promise.reject(error);
});

service.interceptors.response.use(
	async response => {
		if (response && response.data) {
			if (response.status === 200 && response.data.code === 200) {
				store.state.isPageLoading && (store.state.isPageLoading = false);
				return response.data.data;
			} else {
				Toast(response.data.msg);
				store.state.isPageLoading && (store.state.isPageLoading = false);
				return null;
			}
		} else {
			Toast('系统错误');
		}
		store.state.isPageLoading && (store.state.isPageLoading = false);
	},
	async error => {
		let errMsg = '';
		if (!error.response) {
			errMsg = error.message;
		} else {
			errMsg = '系统错误';
		}
		errMsg && typeof errMsg === 'string' && Toast(errMsg);
		store.state.isPageLoading && (store.state.isPageLoading = false);
		console.error(error);
	});

/**
 *
 * @param {String} url
 * @param {Object | null} params
 * @param {Object | null} conf
 */
const axGet = (url, params, conf = {}) => {
	const defConf = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded'
		}
	};
	const ops = {
		url,
		method: 'GET',
		params,
		...defConf,
		...conf,
		cancelToken: new CancelToken(function executor (c) {
			// An executor function receives a cancel function as a parameter
			cancelTokens.push(c);
		})
	};
	return service(ops);
};
const axPost = (url, data = {}, params = {}, conf = {}) => {
	const defConf = {
		headers: {
			'Content-Type': 'application/json; charset=utf-8'
		}
	};
	const ops = Object.assign({
		url,
		method: 'POST',
		params,
		// data: /iPhone OS (10|11)_/.test(window.navigator.userAgent) && !/wechatdevtools/.test(window.navigator.userAgent) ? qs.stringify(data) : data,
		data: data,
		cancelToken: new CancelToken(function executor (c) {
			// An executor function receives a cancel function as a parameter
			cancelTokens.push(c);
		})
	}, defConf, conf);
	return service(ops);
};
const axUpload = (url, data = {}, params = {}, conf = {}) => {
	const defConf = {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	};
	const ops = Object.assign({
		url,
		method: 'POST',
		params,
		data,
		cancelToken: new CancelToken(function executor (c) {
			// An executor function receives a cancel function as a parameter
			cancelTokens.push(c);
		})
	}, defConf, conf);
	return service(ops);
};
export { axGet, axPost, axUpload };