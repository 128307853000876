import { axPost, axGet } from '../base';

const addOrder = (data = {}, params) => {
	return axPost('v1/order', data, params);
};
const addOrderTd = (data = {}, params) => {
	return axPost('v1/orderTd', data, params);
};
const addOrderMh = (data = {}, params) => {
	return axPost('v1/orderMh', data, params);
};
const addOrderSMB = (data = {}, params) => {
	return axPost('v1/orderCommunityInsurance', data, params);
};
const getOrderSMB = (params) => {
	return axGet('v1/orderCommunityInsurance', params);
};
const getDecode = (params) => {
	return axGet('v1/getDecode', params);
};

export {
	addOrder,
	addOrderTd,
	addOrderMh,
    addOrderSMB,
    getOrderSMB,
    getDecode
};