var crypto = require('crypto');

exports.throttle = function (fn, delay = 1000) {
	let timer = null;
	let firstTime = true;

	return function (...args) {
		if (firstTime) {
			// 第一次加载
			fn.apply(this, args);
			firstTime = false;
			return;
		}
		if (timer) {
			// 定时器正在执行中，跳过
			return;
		}
		timer = setTimeout(() => {
			clearTimeout(timer);
			timer = null;
			fn.apply(this, args);
		}, delay);
	};
};

exports.debounce = function (func, delay = 1000) {
	let timeout;
	return function () {
		const context = this; // 指向全局
		const args = arguments;
		if (timeout) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(() => {
			func.apply(context, args); // context.func(args)
		}, delay);
	};
};

exports.vueGetSessionStorage = function (item) {
	const localstorage = window.sessionStorage.getItem(item);
	if (!localstorage) return null;
	return JSON.parse(localstorage);
};
exports.vueSetSessionStorage = function (item, value = {}) {
	window.sessionStorage.setItem(item, JSON.stringify(value));
};

exports.cutdownString = function (string = '', length = 10) {
	if (!string) string = '';
	if (string.length <= length) return string;
	return string.substr(0, length) + '...';
};

exports.b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
	const byteCharacters = atob(b64Data);
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}

	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
};

// 验证是否为手机号
exports.validatePhone = function (value) {
	const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
	if (value === '' || value === undefined || value === null) {
		return false;
	} else {
		if (!reg.test(value) && value !== '') {
			return false;
		} else {
			return true;
		}
	}
};
// 验证是否为身份证号
exports.validateIdCard = function (value) {
	const reg = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
	if (value === '' || value === undefined || value === null) {
		return false;
	} else {
		if (!reg.test(value) && value !== '') {
			return false;
		} else {
			return true;
		}
	}
};

var S4 = function () {
	return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};

exports.generateGuid = function (s = 8) {
	let str = '';
	for (let i = 0; i < s; i++) {
		str += S4();
	}
	return str;
};
// 逢小数进1位
exports.ceil = function (v, e) {
	var a = 1;
	for (; e > 0; a *= 10, e--);
	for (; e < 0; a /= 10, e++);
	return Math.ceil(v * a) / a;
};

// MD5签名
exports.md5 = function (str) {
	var md5sum = crypto.createHash('md5');
	md5sum.update(str);
	str = md5sum.digest('hex');
	return str;
};
// SHA1签名
exports.sha1 = function (str) {
	var md5sum = crypto.createHash('sha1');
	md5sum.update(str);
	str = md5sum.digest('hex');
	return str;
};